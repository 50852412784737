<template>
  <div class="page-login">
    <img src="img/logo.png" width="192" height="192" class="logo" alt="logo">
    <Card>
      <Form :model="formState" :rules="rules" @finish="login" layout="vertical">
        <FormItem name="email" label="">
          <Input v-model:value="formState.email" placeholder="Email" size="large" @blur="customError.email = ''">
            <template #prefix>
              <MailOutlined style="color: rgba(0, 0, 0, 0.25); margin-right: 8px"/>
            </template>
          </Input>
          <Alert style="margin-top: 5px" type="error" v-if="customError.email" :message="customError.email" banner/>
        </FormItem>

        <FormItem name="password" label="">
          <InputPassword v-model:value="formState.password" placeholder="Пароль" size="large"
                         @blur="customError.password = ''">
            <template #prefix>
              <LockOutlined style="color: rgba(0, 0, 0, 0.25); margin-right: 8px"/>
            </template>
          </InputPassword>
          <Alert style="margin-top: 5px" type="error" v-if="customError.password" :message="customError.password"
                 banner/>
        </FormItem>
        <FormItem label="">
          <Switch v-model:checked="formState.save"/>&nbsp;&nbsp;Запомнить на месяц
        </FormItem>
        <FormItem style="margin-bottom: 0">
          <Button type="primary" :loading="loading" html-type="submit" size="large" block>Войти</Button>
        </FormItem>
      </Form>
    </Card>
  </div>
</template>

<script setup>
import {reactive, ref, toRaw} from "vue";
import {MailOutlined, LockOutlined} from "@ant-design/icons-vue";
import {Card, Form, FormItem, Button, Switch, Input, InputPassword, Alert} from "ant-design-vue";

const url = process.env.VUE_APP_API_URL
const formState = reactive({
  email: '',
  password: '',
  save: false,
  ability: 'admin'
});
const rules = {
  email: [
    {
      required: true,
      message: 'Не может быть пустым',
      trigger: 'blur',
    },
    {
      type: 'email',
      message: 'Не верный формат адреса',
      trigger: 'blur',
    },
  ],
  password: [
    {
      required: true,
      min: 2,
      message: ' Не менее 2 символов',
      trigger: 'blur',
    },
  ]
};
const customError = reactive({})

const loading = ref(false)
const login = async () => {
  loading.value = true
  await $axios.post('login', toRaw(formState)).then(r => {
    $cookie.setCookie($auth_name, r.data.token, {
      expire: formState.save ? '30d' : '1d',
      path: '/',
    })
    window.location.href = process.env.BASE_URL
  }).catch(error => {
    if (error.response.status === 422) {
      try {
        for (const [key, value] of Object.entries(error.response.data.errors)) customError[key] = value
      } catch (er) {
        console.info(er)
      }
    }
  })
  loading.value = false
}
</script>

<style lang="less" scoped>
.page-login {
  width: 100%;
  max-width: 380px;
  margin: 20px;
}

.logo {
  display: block;
  margin: 0 auto 20px;
  height: 100px;
  width: auto;
}

h1 {
  text-align: center;
  width: 100%;
}
</style>
